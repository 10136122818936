<!--<template>
  <div id="app" style="">
    <FcnOferta2 />
  </div>
</template>   -->
<script>
import FcnOferta2 from './components/FcnOferta2.vue';

export default FcnOferta2;

/*
export default {
  name: 'app',
  components: {
    FcnOferta2,
  },
};
*/
</script>

<style lang="scss">

</style>
